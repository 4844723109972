import { captureMessage } from '@sentry/react';
import { StytchLogin } from '@stytch/react';
import { StytchEventType } from '@stytch/vanilla-js';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { logout } from '@/api';
import { STYTCH_CONFIG, STYTCH_STYLES } from '@/constants';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';
import { clearCache } from '@/utils';

import type { StytchEvent } from '@stytch/vanilla-js';

import MarketingInfo from '@/components/Signin/MarketingInfo';

const SignIn = () => {
  const { setAppMessage } = useContext(AppContext);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [search] = useSearchParams();
  const message = search.get('error');
  const redirect = search.get('redirect');
  const oauthOnly = search.get('oauth_only') !== null;

  // FIXME: Stytch does not redirect on its own on these types
  // TODO: Add link to issue report
  const onEvent = (event: StytchEvent) => {
    if (
      event.type === StytchEventType.PasswordAuthenticate ||
      event.type === StytchEventType.PasswordCreate
    ) {
      const stytchTokenType = event.type.toLowerCase();
      const token = event.data.session_token;

      const url = `/authenticate?stytch_token_type=${stytchTokenType}&token=${token}${
        redirect ? `&redirect=${redirect}` : ''
      }`;

      navigate(url, { replace: true });
    }
  };

  useEffect(() => {
    if (message) {
      setAppMessage({
        message,
        open: true,
        severity: AppMessageSeverities.Error,
      });
      captureMessage(`Sign in error ${message}`, 'error');
    }
  }, [message]);

  useEffect(() => {
    (async () => {
      await logout();
      clearCache();
      setLoaded(true);
    })();
  }, []);

  return (
    <div className="h-screen w-screen bg-signin bg-cover bg-center bg-no-repeat">
      <div className="w-full flex-1 md:flex md:h-full">
        <MarketingInfo />
        <div className="flex w-full flex-1 items-center justify-center">
          {loaded && (
            <div className="flex w-[400px] flex-col items-center">
              {oauthOnly && (
                <div className="w-full rounded-md bg-blue-100 p-4 text-center text-blue-800 shadow-md">
                  <p className="text-lg font-bold">Mento Employees</p>
                  <p>Please use Google to log in</p>
                </div>
              )}
              <StytchLogin
                callbacks={{
                  onEvent,
                }}
                config={STYTCH_CONFIG(redirect, oauthOnly)}
                styles={STYTCH_STYLES}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
